exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-management-index-tsx": () => import("./../../../src/pages/management/index.tsx" /* webpackChunkName: "component---src-pages-management-index-tsx" */),
  "component---src-pages-management-programs-tsx": () => import("./../../../src/pages/management/programs.tsx" /* webpackChunkName: "component---src-pages-management-programs-tsx" */),
  "component---src-pages-management-shops-ignored-tsx": () => import("./../../../src/pages/management/shops/ignored.tsx" /* webpackChunkName: "component---src-pages-management-shops-ignored-tsx" */),
  "component---src-pages-management-shops-program-[id]-tsx": () => import("./../../../src/pages/management/shops/program/[id].tsx" /* webpackChunkName: "component---src-pages-management-shops-program-[id]-tsx" */),
  "component---src-pages-management-shops-unassigned-tsx": () => import("./../../../src/pages/management/shops/unassigned.tsx" /* webpackChunkName: "component---src-pages-management-shops-unassigned-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-voucher-redeem-[token]-tsx": () => import("./../../../src/pages/voucher/redeem/[token].tsx" /* webpackChunkName: "component---src-pages-voucher-redeem-[token]-tsx" */),
  "component---src-pages-voucher-redeem-approved-tsx": () => import("./../../../src/pages/voucher/redeem/approved.tsx" /* webpackChunkName: "component---src-pages-voucher-redeem-approved-tsx" */),
  "component---src-pages-voucher-redeem-declined-tsx": () => import("./../../../src/pages/voucher/redeem/declined.tsx" /* webpackChunkName: "component---src-pages-voucher-redeem-declined-tsx" */)
}

