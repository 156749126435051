import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import RootElement from './src/components/root-element';

import './src/styles/global.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <RootElement>{element}</RootElement>;
};
