import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import {
  LocalStorageProvider,
  FetchProvider as FetchBaseProvider,
  AuthProvider,
  useAuth,
} from '@reactivers/hooks';
import React, { PropsWithChildren } from 'react';

// import {
//   QueryClient,
//   QueryClientProvider,
//   QueryKey,
// } from "@tanstack/react-query";
// import request from "graphql-request";

// const defaultQueryFn = async ({ queryKey }: { queryKey: QueryKey }) => {
//   const data = await request(
//     "http://localhost:3000/graphql",
//     (queryKey[1] as any).query
//   );
//   return (data as any)[queryKey[0] as string];
// };

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       queryFn: defaultQueryFn,
//     },
//   },
// });

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const RootElement = ({ children }: PropsWithChildren) => {
  return (
    <LocalStorageProvider>
      <AuthProvider>
        <FetchProvider>
          <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </FetchProvider>
      </AuthProvider>
    </LocalStorageProvider>
  );
  // <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
};

const FetchProvider = ({ children }: PropsWithChildren) => {
  const { token, isLoggedIn } = useAuth();

  return (
    (token || !isLoggedIn) && (
      <FetchBaseProvider
        url={process.env.GATSBY_API_URL}
        getAuthorizationHeader={() => {
          return `Bearer ${token}`;
        }}
      >
        {children}
      </FetchBaseProvider>
    )
  );
};
export default RootElement;
